/*
 * Auto Special Offers - allows us to trigger the open of the special offers lightbox dynamically based on user's current page

    ex. call
    autoSpecialOffers.init({
        'section': 'learn,cruise-destinations,alaska-cruises',
        'trade': 'A'
    });
    ex. call
    autoSpecialOffers.init({
        'delay': 0,
        'trade': 'A'
    });
*/

var autoSpecialOffers = {
    /**
     * default configuration 
     */
    'config': {
        'code': 'WWWSPCLOFR',   // set default code to WWWSPCLOFR if code is not defined
        'debug': false,         // {boolean} toggle console logs
        'delay': 3000,          // {number} time in milliseconds to wait for the survey to be displayed
        'maxDaysLastViewed': 14,// max number of day to wait before showing auto popup to user
        'forceDisplay': false,  // {boolean} a means to force the special offers lightbox to open, used for testing
        'section': '',          // the section where the auto special offers was trigger (ie. learn, cruise tips, faq, search). Multiple levels can be applied comma separated (ie. learn,cruise-destinations,alaska-cruises)
        'trade': ''             // set the trade code for the user, and hide trade checkboxes
    },
    
    /**
     * [init] - initializes the auto special offers logic.
     * @param  {object} config - settings for auto special offers for the current page.
     * @param  {boolean or null} boolean is return on display status, null is return if locale is not eligible
     */
    init: function (config) {
        // overwrites default setting with current settings
        if (config && typeof(config) === 'object') {
            $.extend(autoSpecialOffers.config, config);
        }

        if (autoSpecialOffers.config.debug) {
            console.log('autoSpecialOffers config:', autoSpecialOffers.config);
        }

        // makes sure user's locale is eligible for Special Offers
        if (autoSpecialOffers.localeCheck()) {

            // retrieve status from pcl_spo cookie
            var spoObject = autoSpecialOffers.getStatus();
            if (autoSpecialOffers.config.debug) {
                console.log('autoSpecialOffers spoObject', spoObject);
            }

            if (spoObject === null) {
                if (autoSpecialOffers.config.debug) {
                    console.log('autoSpecialOffers open by default');
                }

                // no info available, open lightbox by default and store viewed status in cookie
                autoSpecialOffers.openLightbox(autoSpecialOffers.config.code, autoSpecialOffers.config.section, autoSpecialOffers.config.trade);
                autoSpecialOffers.setView();
                return true;
            } else {
                // checks when last update and completion status to determine if special offers lightbox should be opened
                var displayStatus = autoSpecialOffers.checkStatus(spoObject, autoSpecialOffers.config.maxDaysLastViewed);

                if (autoSpecialOffers.config.debug) {
                    console.log('autoSpecialOffers display:', displayStatus);
                }

                // if true, display the special offers lightbox. if false, and forceOpen is set to true, open special offers lightbox
                if (displayStatus) {
                    setTimeout(function(){
                        autoSpecialOffers.openLightbox(autoSpecialOffers.config.code, autoSpecialOffers.config.section, autoSpecialOffers.config.trade);
                        // set pcl_spo cookie once the lightbox has opened
                        autoSpecialOffers.setView();
                        return true;
                    }, autoSpecialOffers.config.delay);
                } else if (autoSpecialOffers.config.forceDisplay) {
                    setTimeout(function(){
                        autoSpecialOffers.openLightbox(autoSpecialOffers.config.code, autoSpecialOffers.config.section, autoSpecialOffers.config.trade);
                        return false;
                    }, autoSpecialOffers.config.delay);
                }
            }
        } else {
            // locale not eligible for special offers
            if (autoSpecialOffers.config.debug) {
                console.log('autoSpecialOffers locale note eligible, return null');
            }
            return null;
        }
    },

    /**
     * [checkStatus] - check to see if the special offers lightbox should be opened. Only auto open special offers if its been more than 30 days since last viewed, and not completed yet (at least year)
     * @param  {object} spoObject - object in pcl_spo cookie, includes status (viewed vs completed), and timestamp of when the cookie was lastUpdated
     * @param  {number} maxDaysLastViewed - max number of days to wait before showing auto special offers again
     * @return {boolean}          - true if special offers should be displayed, false if not
     */
    checkStatus: function (spoObject, maxDaysLastViewed) {
        if (spoObject === null) {
            // if spoObject is null, user has not seen special offers
            return true;
        } else if (spoObject.status === 'completed') {
            // don't show auto popup when special offers status has been completed
            return false
        } else {
            // status is viewed, do nothing if its been less than max days since pcl_spo was last updated
            var daysLastUpdated = autoSpecialOffers.lastUpdated(spoObject.lastUpdated);

            if (autoSpecialOffers.config.debug) {
                console.log('autoSpecialOffers daysLastUpdated', daysLastUpdated);
            }

            // return if daysLastUpdated is more than maxDaysLastViewed, or if they opened special offers on there own
            if (daysLastUpdated > maxDaysLastViewed || spoObject.formCode === 'SPCLOFFER') {
                return true;
            } else {
                return false;
            }
        }
    },

    /**
     * [lastUpdated] - calculates the number of days since last viewed / completed
     * @param  {number} lastUpdated - unix timestamp
     * @return {number}             - number of days
     */
    lastUpdated: function (lastUpdated) {
        var timestamp = autoSpecialOffers.timestamp,
            timeDiff = timestamp - lastUpdated,
            days = timeDiff / (1000*60*60*24);

        return days;
    },

    /**
     * [timestamp] - new date object based on current time
     * @type {Date}
     */
    timestamp: new Date(),

    /**
     * [expireDatestamp] - sets the expiration date for cookies. 
     * @return {[date object]} - 1 year from timestamp
     */
    expireDatestamp: function () {
        var expire = new Date(autoSpecialOffers.timestamp.getFullYear()+1, autoSpecialOffers.timestamp.getMonth(), autoSpecialOffers.timestamp.getDate(), autoSpecialOffers.timestamp.getHours(), autoSpecialOffers.timestamp.getMinutes());
        return expire;
    },

    /**
     * [localeCheck] - checks global localeData object to see if the user's locale is eligible for Special Offers
     * @return {boolean} - true for locales that have Special Offers, false all other locales
     */
    localeCheck: function () {
        if (typeof localeData === 'object' && localeData.specialOffers === 'true') {
            return true;
        } else {
            return false;
        }
    },

    /**
     * [getStatus] returns the value of pcl_spo
     * @return {object / null}
     */
    getStatus: function () {
        //Retrieve the object form storage
        var spoCookieName = docCookies.nameItem('pcl_spo'),
            spo = docCookies.getItem(spoCookieName);

        if (spo === null || spo === 'undefined') {
            return null;
        } else {
            return JSON.parse(spo);
        }
    },

    /**
     * [setView] common function used to update pcl_spo cookie status to view. This is also called from popup-special-offers.js
     * @param  {string} formCode - Siebel form code of the special offers version
     */
    setView: function (formCode) {
        autoSpecialOffers.updateStatus('viewed',formCode);
    },

    /**
     * [setComplete] common function used to update pcl_spo cookie status to completed. This is also called from specialOffersRegSuccess.jsp
     */
    setComplete: function () {
        autoSpecialOffers.updateStatus('completed');
    },

    /**
     * [updateStatus] updates the pcl_spo cookie based on if it's been displayed or completed
     * @param  {string} status - viewed vs completed
     * @param  {string} formCode - Siebel form code of the special offers version
     */
    updateStatus: function (status, formCode) {
        var spoCookieName = docCookies.nameItem('pcl_spo'),
            timestamp = autoSpecialOffers.timestamp.getTime(),
            spoStatus = autoSpecialOffers.getStatus();

        var spoObject =  { 'status': status, 'lastUpdated': timestamp };
        
        if (!!formCode) {
            spoObject.formCode = formCode;
        } else if (!!spoStatus && !!spoStatus.formCode) {
            spoObject.formCode = spoStatus.formCode;
        }

        docCookies.setItem(spoCookieName, JSON.stringify(spoObject), autoSpecialOffers.expireDatestamp(), '/', 'princess.com', true); // need to swap the null for 'princess.com' and false for true when in dev/stage/prod
    },

    /**
     * [openLightbox] - adds the values for code, section and trade as parameters to then open the special offers lightbox
     * @param  {string} code    - form code to be sent to Siebel
     * @param  {string} section - section on the site where the special offers was triggered
     * @param  {string} trade   - any pre-selected trade, will hide destination options from special offers lightbox
     */
    openLightbox: function (code, section, trade) {
        var params = '',
            addClass = '';
        if (trade != '') {
            addClass = ' no-trades';
            if (params != '') {
                params += '&';
            }
            params += 'trade=' + trade;
        }
        if (section != '') {
            if (params != '') {
                params += '&';
            }
            params += 'section=' + section;
        }
        if (code != '') {
            if (params != '') {
                params += '&';
            }
            params += 'code=' + code;
        }

        jQuery.magnificPopup.open({
            closeMarkup: '<div class="mfp-close-wrapper"><div class="mfp-close-inner-wrapper"><button class="mfp-close icon-no-text" data-test-id="mfp-close"><span class="visuallyhidden">Close Modal</span></button></div></div>',
            items: {
                src: $bookURL+'/captaincircle/specialOfferRegistration.page?' + params
            },
            type: 'iframe',
            mainClass: 'border-box-sizing special-offers' + addClass,
            alignTop: true,
            removalDelay: 300,
            callbacks: {
                open: function() {
                    if (jQuery('#feedbackify').length) {
                        jQuery('#feedbackify').hide();
                    }
                    var item = this.currItem,
						container = this.container;
                    
                    lightboxOpenFocusFix(item, container, 'Sign Up for Special Offers');
                },
                close: function() {
                    $('#container').removeAttr('aria-hidden');
                    if (jQuery('#feedbackify').length) {
                        jQuery('#feedbackify').show();
                    }
                }
            }
        });
    }
};
/**
 * pcl-action-toolbar - Class
 */
class PclActionToolbar extends HTMLElement {
    constructor() {
        super();
        const template = this.getTemplate();
        // Shadow Root & Variables
        this.attachShadow({ mode: 'open' }).appendChild(template.content.cloneNode(true));
        this.actionToolbarDisplay;
        // Elements
        this.$atbMenu;
        this.$atbBtn;
        this.$links;
    }

    /**
    * getTemplate
    * return {DocumentFragment} template
    */
    getTemplate() {
        const template = document.createElement('template');
        const chatEnabled = this.getAttribute('chat')==='true';
        template.innerHTML = /*html*/
            `
                <link rel="stylesheet" href="${this.getAttribute('baseurl') || $wwwURL}/css/global/princess-normalize.css" />
                <link rel="stylesheet" href="${this.getAttribute('baseurl') || $wwwURL}/css/global/web-components/pcl-action-toolbar/action-toolbar.css" />
                <div class="action-toolbar">
                    <div class="action-toolbar-menu">
                        <a class="action-toolbar-link action-toolbar-contactus hide" data-track="action toolbar" data-track-id="Contact Us">Contact Us</a>
                        <a target="_blank" class="action-toolbar-link action-toolbar-phone loc-link hidden-xs-down hide" aria-label="Contact Us Phone number"></a>
                        <button class="action-toolbar-link action-toolbar-chat-link hide" data-track="action toolbar" data-track-id="Chat" aria-label="Live Chat opens a dialog">Live Chat </button>
                        <button class="action-toolbar-link action-toolbar-feedback ${chatEnabled?'':'action-toolbar-feedback--no-border'} global-mfp-modal hide" data-track="action toolbar" data-track-id="Site Feedback" aria-label="Site Feedback opens a dialog">Site Feedback</button>
                    </div>
                    <button class="action-toolbar-btn"  id="action-toolbar-btn" aria-haspopup="true" aria-expanded="true" data-track="action toolbar" data-track-id="Hide">
                        <svg class="svg-icon action-toolbar-btn-icon" aria-hidden="false" height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title>chevron</title><polygon points="6.86 1 13.834 12 6.86 23 10.003 23 17.14 12 10.003 1 6.86 1"></polygon>
                        </svg>
                        <span class="action-toolbar-btn-text action-toolbar-show-text">Need Assistance?</span>
                        <span class="action-toolbar-btn-text action-toolbar-hide-text visuallyhidden">Hide</span>
                    </button>
                </div>
            `;
        return template;
    }

    /**
     * Triggered when the DOM element is attached
     */
    connectedCallback() {
        // Update HTML Element Selectors
        this.$atbMenu = this.shadowRoot.querySelector('.action-toolbar-menu');
        this.$atbBtn = this.shadowRoot.querySelector('.action-toolbar-btn');
        this.$links = {
            contactus: this.shadowRoot.querySelector('.action-toolbar-contactus'),
            phone: this.shadowRoot.querySelector('.action-toolbar-phone'),
            feedback: this.shadowRoot.querySelector('.action-toolbar-feedback'),
            chat: this.shadowRoot.querySelector('.action-toolbar-chat-link')
        }

        // Update Links
        this.updateLinks();

        this.actionToolbarDisplay = this.getDisplaySetting();
        if (this.actionToolbarDisplay) {
            this.notifyStatusChange('isToolbarVisible', true);
        } else {
            this.hide();
        }

        // Event Listeners
        this.$atbBtn.addEventListener('click', (event) => {
            this.handleDisplay();
        });

        this.$links.chat.addEventListener('click', (event) => {
            window.dispatchEvent(new Event('pcl-action-toolbar.onclickchat'));
        });

        this.$links.feedback.addEventListener('click', (event) => {
            if(this.getAttribute('site-feedback-in-new-tab')) {
                window.open('https://princess.qualtrics.com/jfe/form/SV_cM7FjG7sxfAvj8y');
            } else {
                window.dispatchEvent(new CustomEvent('web-component.onClick', {
                    detail: {
                        src: this.getAttribute('feedback'),
                        type: 'iframe',
                        mainClass: "qualtrics-feedback"
                    }
                }));
            }
        });

        window.addEventListener('web-component.setfocus', (event) => {
            const selector = event.detail.elementSelector;
            this.shadowRoot.querySelector(selector).focus();

        })
    }

    /**
     * List of attribues to watch changes for
     */
    static get observedAttributes() {
        return ['contactus', 'chat', 'feedback', 'phone', 'site-feedback-in-new-tab'];
    }

    /**
     * Triggered when attributes are added, modified or removed
     * @param {string} name - name of the attribute
     * @param {string} oldValue - old value of the attribute
     * @param {string} newValue - new value of the attribute
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (!newValue || newValue === 'false') {
            this.$links && this.$links[name].classList.add('hide');
            return;
        }
        if (oldValue !== newValue && this.$links && this.$links[name]) {
            this.updateLinks();
        }
    }

    /**
     * Updates 'href' for links
     */
    updateLinks() {
        for (const [key, value] of Object.entries(this.$links)) {
            let attrValue = this.getAttribute(key);
            if (key && value && attrValue) {
                if (key === 'chat') {
                    if (attrValue === 'true') this.$links[key].classList.remove('hide');
                    continue;
                }
                if (key === 'feedback') {
                    this.$links[key].classList.remove('hide');
                    continue;
                }
                if (key === 'phone') {
                    this.$links[key].setAttribute('aria-label', `${attrValue} Contact Us Phone number`);
                    this.$links[key].innerHTML = attrValue;
                    attrValue = `tel:${attrValue}`;
                }
                this.$links[key].setAttribute('href', attrValue);
                this.$links[key].classList.remove('hide');
            }
        }
    }

    /**
     * saveDisplaySetting - saves current action toolbar display status in sessionStorage so that we can preserve display on next page load
     * @param  {boolean} status - true means action toolbar is visible, false it's hidden
    */
    saveDisplaySetting(status) {
        sessionStorage.setItem('actionToolbarDisplay', status);
    }

    /**
    * getDisplaySetting - looks up the current action toolbar display status in sessionStorage. If its null, we'll display the action toolbar by default
    * return {boolean} status - true means action toolbar is visible, false it's hidden
    */
    getDisplaySetting() {
        return sessionStorage.getItem('actionToolbarDisplay') === 'false' ? false : true;
    }

    /**
    * hide - update the dom to hide the action toolbar
    */
    hide() {
        this.$atbBtn.setAttribute('aria-expanded', 'false');
        this.$atbMenu.classList.add('action-toolbar-menu-lowered');
        this.notifyStatusChange('isToolbarVisible', false);
    }

    /**
     * show - update the dom to show the action toolbar
     * @param  {jQuery Object} $actionToolbarBtn - action toolbar show/hide button
    */
    show() {
        this.$atbBtn.setAttribute('aria-expanded', 'true');
        this.$atbMenu.classList.remove('action-toolbar-menu-lowered');
        this.notifyStatusChange('isToolbarVisible', true);
    }

    /**
     * notifyStatusChange - sets the display status in ember object
     * @param  {string}  property - property name
     * @param  {boolean} status   - true means action toolbar is visible, false it's hidden
    */
    notifyStatusChange(property, status) {
        const obj = window['pclSearch'];
        if (obj) {
            obj.set(property, status);
        }
    }

    /**
    * displayBtn - tests to see if action toolbar needs to be show/hide based on aria-expanded status
    */
    handleDisplay() {
        if (this.$atbBtn.getAttribute('aria-expanded') === 'true') {
            this.hide();
            this.saveDisplaySetting(false);
        } else {
            this.show();
            this.saveDisplaySetting(true);
        }

        // remove focus after click
        this.$atbBtn.blur();
    }
}

if (window.self === window.top && !window.customElements.get('pcl-action-toolbar')) {
    window.customElements.define('pcl-action-toolbar', PclActionToolbar);
}
